import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import "../../../../../dist/css/bolt-css.css";
import Icon from "../../../../../src/assets/icons/visa.inline.svg";
import iconurl from "../../../../../src/assets/icons/skull.svg";
import * as React from 'react';
export default {
  useState,
  useRef,
  useEffect,
  Playground,
  Props,
  Icon,
  iconurl,
  React
};