export const iconsNames = [
  'tw-i-accounts',
  'tw-i-alert-circle',
  'tw-i-arrow-down',
  'tw-i-arrow-left',
  'tw-i-arrow-right',
  'tw-i-arrow-up',
  'tw-i-bag',
  'tw-i-bar-chart',
  'tw-i-bars',
  'tw-i-box',
  'tw-i-calendar',
  'tw-i-cards',
  'tw-i-check',
  'tw-i-chevron-down',
  'tw-i-chevron-left',
  'tw-i-chevron-right',
  'tw-i-chevron-up',
  'tw-i-clock',
  'tw-i-close',
  'tw-i-corner-down-left',
  'tw-i-dialog-canceled',
  'tw-i-dialog-help',
  'tw-i-dialog',
  'tw-i-dot',
  'tw-i-edit',
  'tw-i-eye-off',
  'tw-i-eye',
  'tw-i-file-text',
  'tw-i-folder',
  'tw-i-glob',
  'tw-i-home',
  'tw-i-layout',
  'tw-i-lock',
  'tw-i-luggage',
  'tw-i-mail',
  'tw-i-map-pin',
  'tw-i-maximize',
  'tw-i-menu',
  'tw-i-minimize',
  'tw-i-money-in',
  'tw-i-money-out',
  'tw-i-money',
  'tw-i-navigation',
  'tw-i-notifications',
  'tw-i-search',
  'tw-i-settings-bar',
  'tw-i-settings',
  'tw-i-share',
  'tw-i-skull',
  'tw-i-statements',
  'tw-i-thumbs-up',
  'tw-i-umbrella',
  'tw-i-pismo-wave',
  'tw-i-pismo-logo',
  'tw-i-blank',
  'tw-i-amex',
  'tw-i-maestro',
  'tw-i-master',
  'tw-i-visa',
  'tw-i-master-tiny',
  'tw-i-visa-tiny',
  'tw-i-bankBca',
];
