// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---readme-md": () => import("./../../../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-button-button-mdx": () => import("./../../../../src/button/button.mdx" /* webpackChunkName: "component---src-button-button-mdx" */),
  "component---src-colors-colors-mdx": () => import("./../../../../src/colors/colors.mdx" /* webpackChunkName: "component---src-colors-colors-mdx" */),
  "component---src-day-day-mdx": () => import("./../../../../src/day/day.mdx" /* webpackChunkName: "component---src-day-day-mdx" */),
  "component---src-divider-divider-mdx": () => import("./../../../../src/divider/divider.mdx" /* webpackChunkName: "component---src-divider-divider-mdx" */),
  "component---src-form-control-form-control-mdx": () => import("./../../../../src/form-control/form-control.mdx" /* webpackChunkName: "component---src-form-control-form-control-mdx" */),
  "component---src-icons-icons-mdx": () => import("./../../../../src/icons/icons.mdx" /* webpackChunkName: "component---src-icons-icons-mdx" */),
  "component---src-input-input-mdx": () => import("./../../../../src/input/input.mdx" /* webpackChunkName: "component---src-input-input-mdx" */),
  "component---src-list-item-list-item-mdx": () => import("./../../../../src/list-item/list-item.mdx" /* webpackChunkName: "component---src-list-item-list-item-mdx" */),
  "component---src-menu-button-menu-button-mdx": () => import("./../../../../src/menu-button/menu-button.mdx" /* webpackChunkName: "component---src-menu-button-menu-button-mdx" */),
  "component---src-menu-menu-mdx": () => import("./../../../../src/menu/menu.mdx" /* webpackChunkName: "component---src-menu-menu-mdx" */),
  "component---src-modal-modal-mdx": () => import("./../../../../src/modal/modal.mdx" /* webpackChunkName: "component---src-modal-modal-mdx" */),
  "component---src-option-input-option-input-mdx": () => import("./../../../../src/option-input/option-input.mdx" /* webpackChunkName: "component---src-option-input-option-input-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-select-select-mdx": () => import("./../../../../src/select/select.mdx" /* webpackChunkName: "component---src-select-select-mdx" */),
  "component---src-shadows-shadows-mdx": () => import("./../../../../src/shadows/shadows.mdx" /* webpackChunkName: "component---src-shadows-shadows-mdx" */),
  "component---src-sidebar-sidebar-mdx": () => import("./../../../../src/sidebar/sidebar.mdx" /* webpackChunkName: "component---src-sidebar-sidebar-mdx" */),
  "component---src-snackbar-snakbar-mdx": () => import("./../../../../src/snackbar/snakbar.mdx" /* webpackChunkName: "component---src-snackbar-snakbar-mdx" */),
  "component---src-station-station-mdx": () => import("./../../../../src/station/station.mdx" /* webpackChunkName: "component---src-station-station-mdx" */),
  "component---src-svg-example-svg-example-mdx": () => import("./../../../../src/svg-example/svg-example.mdx" /* webpackChunkName: "component---src-svg-example-svg-example-mdx" */),
  "component---src-topbar-topbar-mdx": () => import("./../../../../src/topbar/topbar.mdx" /* webpackChunkName: "component---src-topbar-topbar-mdx" */),
  "component---src-typography-typography-mdx": () => import("./../../../../src/typography/typography.mdx" /* webpackChunkName: "component---src-typography-typography-mdx" */)
}

