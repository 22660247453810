import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import { OptionInput } from "../../../../../dist/js/bolt";
import "../../../../../dist/css/bolt-css.css";
import * as React from 'react';
export default {
  useState,
  useRef,
  useEffect,
  Playground,
  Props,
  OptionInput,
  React
};