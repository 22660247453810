module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codefresh/volume/bolt/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{"showPlaygroundEditor":false},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/codefresh/volume/bolt/.docz","base":"/","source":"./","gatsby-root":null,"files":["Readme.md","src/**/*.mdx"],"public":"/public","dest":"/build","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Bolt","description":"My awesome app using docz","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/codefresh/volume/bolt","templates":"/codefresh/volume/bolt/node_modules/docz-core/dist/templates","docz":"/codefresh/volume/bolt/.docz","cache":"/codefresh/volume/bolt/.docz/.cache","app":"/codefresh/volume/bolt/.docz/app","appPackageJson":"/codefresh/volume/bolt/package.json","appTsConfig":"/codefresh/volume/bolt/tsconfig.json","gatsbyConfig":"/codefresh/volume/bolt/gatsby-config.js","gatsbyBrowser":"/codefresh/volume/bolt/gatsby-browser.js","gatsbyNode":"/codefresh/volume/bolt/gatsby-node.js","gatsbySSR":"/codefresh/volume/bolt/gatsby-ssr.js","importsJs":"/codefresh/volume/bolt/.docz/app/imports.js","rootJs":"/codefresh/volume/bolt/.docz/app/root.jsx","indexJs":"/codefresh/volume/bolt/.docz/app/index.jsx","indexHtml":"/codefresh/volume/bolt/.docz/app/index.html","db":"/codefresh/volume/bolt/.docz/app/db.json"}},
    }]
